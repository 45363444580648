import cart from '../../api/cart'
const state = () => ({
    cart: {
        lineItems: [],
        itemSubtotalAsCurrency: "$0.00",
        totalQty: 0
    }
})

// getters
const getters = {
    getCartProducts: (state, getters, rootState) => {
        return state.cart.lineItems;
    },

    getIsStoreOrder: (state, getters, rootState) => {
        // return state.cart.lineItems.reduce((acc, cur) => {
        //     return acc + cur.qty;
        // }, 0)
        return state.cart.storeOrder;
    },

    getCartProductCount: (state, getters, rootState) => {
        // return state.cart.lineItems.reduce((acc, cur) => {
        //     return acc + cur.qty;
        // }, 0)
        return state.cart.totalQty;
    },

    getCartSubtotalTotalPrice: (state) => {
        // let items = state.cart.lineItems;
        // let price = 0;
        // for(let i = 0; i < items.length; i++)
        // {
        //     // price += parseFloat((items[i].price * 1.13 + items[i].adjustments[0].amount).toFixed(2));
        //     price += items[i].total
        // }
        // // return state.cart.itemSubtotalAsCurrency;
        // return "$" + price.toFixed(2).toString();
        // console.log(state.cart.itemTotalAsCurrency);
        return state.cart.itemTotalAsCurrency;
    },

    getCartShippingPrice: (state) => {
        // return state.cart.orderAdjustments.find(adj => adj.type === "shipping").amountAsCurrency;
        // console.log(state.cart);
        // console.log(state.cart.totalShippingCost);
        if (state.cart.totalShippingCost == 0) {
           return state.cart.totalShippingCost;
        } else {
            return state.cart.totalShippingCostAsCurrnecy;
        }
    },

    getCartTax: (state) => {
        // return state.cart.orderAdjustments.find(adj => adj.type === "tax").amountAsCurrency;
        console.log(state.cart.totalTaxAsCurrency);
        return state.cart.totalTaxAsCurrency;
    },

    getCartTotalPrice: (state) => {
        // let shipping = state.cart.orderAdjustments.find(adj => adj.type === "shipping").amount;
        // let tax = state.cart.orderAdjustments.find(adj => adj.type === "tax").amount;
        // let items = state.cart.lineItems;
        // let sub = 0;
        // for(let i = 0; i < items.length; i++)
        // {
        //     // sub += parseFloat((items[i].price * 1.13 + items[i].adjustments[0].amount).toFixed(2));
        //     sub += items[i].total;
        // }
        // let total = (shipping + tax + sub).toFixed(2)
        // return "$" + total;
        console.log(state.cart.outstandingBalanceAsCurrency);
        return state.cart.totalAsCurrency;
    },

    getQuantityById: (state, getters) => (id) => {
        let item = getters.getItemById(id);
        if (item) {
            return item.qty;
        }
        return 0;
    },

    getItemById: (state, getters) => (id) => {
        return getters.getCartProducts.find(({purchasableId}) => purchasableId == id);
    }
}

// actions
const actions = {
    async emptyCart({state, commmit}) {

    },
    async setMembershipType ({ state, commit }, {type}) {
        if(state.cart.lineItems.length <= 0) {
            try {
                await cart.setMembershipType(type)
            } catch (e) {
                console.log("Unable to add");
                return
            }
            console.log("Added successfully");

            await this.dispatch('cart/getCartFromServer');
        }
    },
    async setCartType ({ state, commit }, {gatewayId}) {
            if(state.cart.lineItems.length <= 0) {
                try {
                    await cart.setCartType(gatewayId)
                } catch (e) {
                    console.log("Unable to add");
                    return
                }
                console.log("Added successfully");

                await this.dispatch('cart/getCartFromServer');
            }
    },
    async addProductToCart ({ state, commit }, {product, quantity}) {
        if (product.stock > 0) {
            try {
                await cart.addToCart(product, quantity)
            } catch (e) {
                console.log("Unable to add");
                return
            }
            console.log("Added successfully");

            await this.dispatch('cart/getCartFromServer');
        }
    },
    async updateLineItemQuantity({state, commit}, {lineItemId, quantity}) {
        try {
            await cart.updateLineItemQuantity(lineItemId, quantity)
        } catch (e) {
            let text = "Unable to update cart item: " + e.message;
            console.log(text);
            throw new Error(text)
        }
        console.log("success");

        await this.dispatch('cart/getCartFromServer');
    },
    async getCartFromServer ( {state, commit} ) {
        let cartData = {};
        try {
            cartData = await cart.getCart();
        } catch (error) {
            console.log(error)
            return;
        }
        commit('setCart', cartData);
    }
}

// mutations
const mutations = {
    setCart (state, cart) {
        state.cart = cart
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
