<template>

  <div style="max-height: inherit;">
    <div v-if="Gallery" class="reviews relative h-full pb-8 lg:pb-0 overflow-hidden">
      <div v-for="(data, index) in Gallery.assets" v-if="indexDisplayed === index && Gallery.assets.length >= 1" class="flex flex-wrap w-full justify-center overflow-hidden relative font-GillSans " >



        <img  class=" !md:h-[75%] w-auto max-h-[900px]" style="
object-fit: cover;" :src=data.url  alt=""/>





      </div>





    </div>

    <div class="z-40 flex justify-center basis-full mt-1 lg:mt-5 pb-4">
      <a v-for="(item, index) in Gallery.assets" class="mx-2">
        <svg v-if="indexDisplayed === index" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewbox="0 0 13 13">
          <g class=" svg circle-color" fill="#fff" stroke="#707070" stroke-width="1">
            <circle cx="6.5" cy="6.5" r="6.5" stroke="none"/>
            <circle cx="6.5" cy="6.5" r="6" fill="none"/>
          </g>
        </svg>
        <svg @click="changeReview(index)" v-else xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewbox="0 0 13 13">
          <g class="svg"  fill="#fff" stroke="#707070" stroke-width="1">
            <circle cx="6.5" cy="6.5" r="6.5" stroke="none"/>
            <circle cx="6.5" cy="6.5" r="6" fill="none"/>
          </g>
        </svg>
      </a>
    </div>

<!--    <div class="z-40 hidden xl:flex justify-center basis-full mt-1 lg:mt-5">-->
<!--      <a v-for="(item, index) in Gallery.assets" class="mx-2">-->
<!--        <svg v-if="indexDisplayed === index" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewbox="0 0 13 13">-->
<!--          <g class=" svg circle-color" fill="#fff" stroke="#707070" stroke-width="1">-->
<!--            <circle cx="6.5" cy="6.5" r="6.5" stroke="none"/>-->
<!--            <circle cx="6.5" cy="6.5" r="6" fill="none"/>-->
<!--          </g>-->
<!--        </svg>-->
<!--        <svg @click="changeReview(index)" v-else xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewbox="0 0 13 13">-->
<!--          <g class="svg"  fill="#fff" stroke="#707070" stroke-width="1">-->
<!--            <circle cx="6.5" cy="6.5" r="6.5" stroke="none"/>-->
<!--            <circle cx="6.5" cy="6.5" r="6" fill="none"/>-->
<!--          </g>-->
<!--        </svg>-->
<!--      </a>-->
<!--    </div>-->


  </div>




</template>

<script>
import Checkbox from 'vue-material-checkbox'
import gql from "graphql-tag";
export default {
  name: "weddingGallery",
  components: { Checkbox },
  apollo: {
    Gallery: {
      query: gql`query getPhotos {
       assets(limit: 20, volume: "wedding") {

    ... on wedding_Asset {
      url
      title

    }
  }
  }`,
      update: data => data,
    },
  },

  data() {
    return {
      indexDisplayed: 0,
      dummyData: [
        {
          title: "2017 Riesling CSV, Beamsville Bench, Estate Grown",
          subtitle: "Top Rated Canadian Riesling",
          desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?",
          rating: "94"
        },
        {
          title: "Review 2",
          subtitle: "Top Rated Canadian Riesling",
          desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?",
          rating: "96"
        }
      ],
      timer: null,

      Gallery: [],
    }},
  mounted: function () {
    if(this.Gallery) {
      this.timer = setInterval(() => {
        if (this.indexDisplayed === (this.Gallery.assets.length - 1))
          this.indexDisplayed = 0;
        else {
          this.indexDisplayed++;
        }
      }, 10000)
    }// 10 seconds on the slider
  },
  methods: {
    changeReview(index) {
      this.indexDisplayed = index;
      console.log(this.Gallery)
    }
  }
}
</script>
<style>

</style>