<template>

  <div>
    <div v-if="Reviews.entries.length >= 1" class="reviews h-full md:min-h-[50vh] pb-8 lg:pb-0 !2xl:max-h-[60vh] !3xl:max-h-[55vh]  overflow-hidden flex flex-wrap" style="background-color: rgb(214, 214, 213)">
      <div v-for="(data, index) in Reviews.entries" v-if="indexDisplayed === index" class="block lg:flex w-full justify-center items-center overflow-hidden basis-full px-8 md:px-12 lg:px-28 font-GillSans">
      <div class="w-full lg:w-2/4 flex flex-wrap md:flex-no-wrap justify-center items-center">
        <div class="text-[76px]">
          <div class="text-[#4a86a2] text-center">{{data.ratingPoint}}</div>
          <img class="max-w-[300px]" :src="data.publicationImage">
        </div>
        <div>
          <img class="m-8 max-h-[300px] md:max-[450px] !lg:max-h-[500px] xl:max-h-[575px] 2xl:max-h-[625px] " :src="data.techImage"/>
        </div>
      </div>
      <div class="w-full lg:w-2/4">
        <div class="text-[1.6875rem] text-font-title uppercase mb-6">Reviews & Accolades</div>
        <div class="text-[1.6875rem] text-[#4a86a2] ">{{ data.wineName }}</div>
        <div class="text-[1.6875rem] text-font-title mb-6">{{ data.reviewPublication }}  <span class="italic text-[18px]" > - {{data.reviewAuthor}}</span></div>
        <div v-if="data.cmsReview" class="text-[1.125rem]   text-font-title">{{ data.cmsReview }}</div>
<!--        <div v-else-if="!data.cmsReview" class="text-[18px]">{{ data.reviewRaw }}</div>-->
        <div v-if="data.currentVintage && data.stock >= 1" class=" mt-5 mb-5  lg:mb-0" >




          <div class="text-[1.6875rem] text-[#4a86a2] lg:mb-20 "> ${{data.winePrice}} </div>

          <div class="flex justify-center lg:justify-start">
              <div v-if="promotionDisplay(data)" class="relative white-banner mr-4 hover:bg-sky-700">

                <img v-if="data.field_promoType_qkkxzmgw == 'sale'" class="white-banner hover:bg-sky-700" src='/assets/svg/sale.svg'>
                <img v-else class="white-banner hover:bg-sky-700" src='/assets/svg/newVintage.svg'>
                <div class="text-white w-full button-style">{{data.field_promoMessage_xyvkrwsy}} </div>
              </div>
            </div>

          <div class="flex justify-center lg:justify-start">
          <div class="white-banner lg:mt-5 mt-2.5 lg:mb-0" style="position: relative;">
            <a :href="'/individual-wine?wine=' + data.cmsSlug + '&vintage=cur'">
              <img class="blue-banner" src='/assets/svg/banner.svg'>
              <div class="text-white w-full button-style"> BUY WINE
              </div>
            </a>
          </div>
          </div>
        </div><div v-if="data.currentVintage && data.stock <= 0" class=" mt-5 mb-5  lg:mb-0" >




          <div class="text-[1.6875rem] text-[#4a86a2] lg:mb-20 "> ${{data.winePrice}} </div>

          <div class="flex justify-center lg:justify-start">
              <div v-if="promotionDisplay(data)" class="relative white-banner mr-4 hover:bg-sky-700">

                <img v-if="data.field_promoType_qkkxzmgw == 'sale'" class="white-banner hover:bg-sky-700" src='/assets/svg/sale.svg'>
                <img v-else class="white-banner hover:bg-sky-700" src='/assets/svg/newVintage.svg'>
                <div class="text-white w-full button-style">{{data.field_promoMessage_xyvkrwsy}} </div>
              </div>
            </div>

          <div class="flex justify-center lg:justify-start">
          <div class="white-banner lg:mt-5 mt-2.5 lg:mb-0" style="position: relative;">
            <a :href="'/individual-wine?wine=' + data.cmsSlug + '&vintage=cur'">
              <img class="blue-banner" src='/assets/svg/banner.svg'>
              <div class="text-white w-full button-style"> LEARN MORE
              </div>
            </a>
          </div>
          </div>
        </div>
        <div v-if="!data.currentVintage" class="mt-5 mb-5 lg:mt-20 lg:mb-0">
          <div class="flex justify-center lg:justify-start">
          <div class="white-banner lg:mt-5 mt-2.5 lg:mb-0" style="position: relative;">
            <a :href="'/individual-wine?wine=' + data.cmsSlug + '&vintage=back'">
              <img class="blue-banner" src='/assets/svg/banner.svg'>
              <div class="text-white w-full button-style"> LEARN MORE
              </div>
            </a>
          </div>
          </div>
        </div>

      </div>


      </div>

      <div class="z-40 flex justify-center basis-full pt-4 pb-4 lg:pb-8 3xl:mb-10">
        <a v-for="(item, index) in Reviews.entries" class="mx-2">
          <svg v-if="indexDisplayed === index" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewbox="0 0 13 13">
            <g class=" svg circle-color" fill="#fff" stroke="#707070" stroke-width="1">
              <circle cx="6.5" cy="6.5" r="6.5" stroke="none"/>
              <circle cx="6.5" cy="6.5" r="6" fill="none"/>
            </g>
          </svg>
          <svg @click="changeReview(index)" v-else xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewbox="0 0 13 13">
            <g class="svg"  fill="#fff" stroke="#707070" stroke-width="1">
              <circle cx="6.5" cy="6.5" r="6.5" stroke="none"/>
              <circle cx="6.5" cy="6.5" r="6" fill="none"/>
            </g>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from 'vue-material-checkbox'
import gql from "graphql-tag";
export default {
  name: "ReviewsAccolades",
  props: ['image'],
  components: { Checkbox },
  apollo: {
    Reviews: {
      query: gql`query GetReviews {
        entries(limit: 6, section: "reviews", type: "cmsReviews") {
    title
    ... on reviews_cmsReviews_Entry {
      cmsReview
      ratingOther
      ratingPoint
      ratingStar
      reviewAuthor
      reviewDate
      reviewHeadline
      reviewMonth
      reviewPublication
      reviewPublished
      reviewRaw
      reviewSku
      reviewSweetness
      reviewVintage
      reviewWebsite
      reviewWine
      reviewWineType
      reviewYear
      webstoreReview
      currentVintage
      techImage
      cmsSlug
      wineName
      publicationImage
      winePrice
      stock
    }
  }
  }`,
      update: data => data,
    },
    promos: {
      query: gql`query GetEntries {
       entries(section:"promo") {
    ... on promo_default_Entry {
      id
      promoMessage
      promoSku
      promoType(label: false)
      displayUntil
    }
  }
  }`,
      update: data => data,
    },
  },


  data() {
    return {
      indexDisplayed: 0,
      dummyData: [
        {
          title: "2017 Riesling CSV, Beamsville Bench, Estate Grown",
          subtitle: "Top Rated Canadian Riesling",
          desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?",
          rating: "94"
        },
        {
          title: "Review 2",
          subtitle: "Top Rated Canadian Riesling",
          desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?",
          rating: "96"
        }
      ],
      timer: null,
      Reviews: [],
      promos: [],
    }},
  mounted: function () {
    this.timer = setInterval(() => {
      if(this.indexDisplayed === (this.Reviews.entries.length - 1))
        this.indexDisplayed = 0;
      else {
        this.indexDisplayed++;
      }
    }, 10000)
  // }, 100000) // 10 seconds on the slider
  },
  methods: {
    changeReview(index) {
      this.indexDisplayed = index;
    },
    promotionDisplay: function (sku){

      console.log(sku)
      console.log(this.promos)


      let currentDate = new Date().toLocaleDateString();
      if(this.promos.entries) {

        for (let i = 0; i < this.promos.entries.length; i++) {
          let displayUntil = this.promos.entries[i].displayUntil;
          console.log(currentDate);
          console.log(displayUntil);

          if (this.promos.entries[i].promoSku === sku.reviewSku && currentDate <= displayUntil.substring(0, currentDate.length)) {
            sku.field_promoMessage_xyvkrwsy = this.promos.entries[i].promoMessage;
            sku.field_promoType_qkkxzmgw = this.promos.entries[i].promoType;
            console.log("great success")
            return true;
          }
        }
      }
      console.log('No Success')
      return false;

    },


  },
  computed: {

  }
}
</script>
<style>

</style>