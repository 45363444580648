<template>
  <div id="randy">
    <div id="site-logo">
      <a href="/" title="Home">
        <img src="/assets/logos/MyCaveRewards.png" style="width:100%;"
             alt="Home" id="logo" />
      </a>
    </div>
    <div id="print-logo">
      <img src="/assets/logos/myCaveRewards.png" width="200" border="0" />
    </div>
    <h1 v-if="!$apollo.queries.entry.loading" class="shop-title">{{entry.mainTitle}}</h1>

    <div class="px-4" v-html="entry.paragraphone"></div>


    <h1 class="shop-title"> {{entry.heading}} </h1>

    <div class="px-4" v-html="entry.paragraphTwo"></div>

    <h1 class="shop-title">{{entry.headingTwo}}</h1>

    <div class="px-4" v-html="entry.paragraphThree"></div>
    <div class="text-center">
    <a href="#" class="ui-button" style="align-content: center; justify-content: center;">Redeem Your Rewards</a>
    </div>
    <h1 class="shop-title"></h1>

    <div class="px-4" v-html="entry.footer"></div>



  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "CaveRewardsComponent",
  apollo: {
    entry: {
      query: gql`query GetEntries {
         entry(slug: "mycave-rewards") {
    ... on myCaveRewards_myCaveRewards_Entry {
      title
      paragraphThree
      paragraphTwo
      paragraphone
      footer
      heading
      headingTwo
    }
  }

}`
    }
  },
  data() {
    return {
      entry:{},

    }}

}


</script>