<template>
  <div>
    <div id="site-logo" class="pb-4">
      <a href="/" title="Home">
        <img src="/assets/logos/cavespring-vineyard-logo.png" style="width:100%;"
             alt="Home" id="logo" />
      </a>
    </div>
    <div id="print-logo">
      <img src="/assets/logos/cavespring-vineyard-logo.png" width="200" border="0" />
    </div>
    <div v-if="!$apollo.queries.entry.loading" class="px-4 page-content">
    <h1 class="shop-title mx-3">{{entry.mainTitle}}</h1>

      <div class="px-4 py-2" v-html="entry.paragraphone"></div>


<!--    <h1 class="shop-title"> {{entry.heading}} </h1>-->
      <hr class="mx-3">
    <div class="text-center pb-1">
      <img src="/assets/logos/myCaveBottomLogo.png" alt="myCave Club Logo" style="max-width: 200px;">
    </div>

    <div class="px-4 py-2" v-html="entry.paragraphTwo">

    </div>
    <div class="text-center mb-4">
      <a data-toggle="modal" data-target="#login-modal" class="ui-button px-4">JOIN </br> myCaveClub</a>
      <a data-toggle="modal" data-target="#login-modal" class="ui-button px-4" >GIFT</br> myCaveClub</a>
    </div>
      <hr class="mx-3">

<!--    <h1 class="shop-title">{{entry.headingTwo}}</h1>-->
    <div class="text-center pb-1"><img src="/assets/logos/myCaveRewards.png" alt="myCave Rewards Logo" style="max-width: 200px;"></div>
    <div class="px-4 py-2 list-bullets" v-html="entry.paragraphThree"></div>

<!--    <div id="site-logo" class="text-center">-->
<!--      <img src="/assets/logos/myCaveBottomLogo.png" style="width: 100%;">-->
<!--    </div>-->
    </div>


  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "WineShopComponent",
  apollo: {
    entry: {
      query: gql`query GetEntries {
         entry(slug: "wineshop-home") {
    ... on wineshopHome_wineshopHome_Entry {
      mainTitle
      paragraphThree
      paragraphTwo
      paragraphone
      heading
      headingTwo
    }
  }
  }`
    }
  },
  data() {
    return {
      entry:{},

    }}


}
</script>