<template>
  <div class="w-full text-xs md:text-base lg:w-2/6 flex justify-center mt-8 mb-6 text-font-h3 relative z-20">


  
    <span v-if="winetype === 'Red' || winetype === 'White'" class=" uppercase"> <a href="/wine">OUR WINES </a> > <a
        :href="'/wine?t=' + winetype">
        {{ winetype }} </a> >
      <a :href="'/wine?v=' + winevariety + '&t=' + winetype"> {{ winevariety }} </a> > <a>{{winename}} </a> </span>
    <span v-else class=" uppercase"> <a href="/wine">OUR WINES </a> > <a
        :href="'/wine?t=' + winetype">
        {{ winetype }} </a> >
        <a>{{winename}} </a> </span>

  </div>
</template>

<script>
export default {
  name: "TechDataBreadcrumbsComponent",
  props: ['winetype','winevariety','winename'],
  data() {
    return {
      
    }

  },
  computed: {

  },

  methods: {

  },
}
</script>