<template>

  <div>
    <div id="site-logo">
      <a href="/" title="Home">
        <img src="/assets/logos/myCaveRewards.png" style="width:100%;"
             alt="Home" id="logo" />
      </a>
    </div>
    <div id="print-logo">
      <img src="/assets/logos/myCaveRewards.png" width="200" border="0" />
    </div>
    <h1 class="shop-title"> Welcome to myCave Account </h1>

    <h6 class="mx-4 cave-rewards" style="text-decoration: none; border-bottom: none;"> USER NAME </h6>

    <div class="row my-3">
      <div id="left" class="col-6" style="text-align: center">
        <div>
        <a href="#" class="ui-button px-4 my-3">ABOUT </br> myCaveClub</a>
        </div>
        <div>
        <a href="#" class="ui-button px-4 my-3" >JOIN </br> myCaveClub</a>
        </div>
        <div>
        <a href="#" class="ui-button px-4 my-3" >GIFT </br> myCaveClub</a>
        </div>
        <div>
        <a href="#" class="ui-button px-4 my-3">MANAGE </br> myCaveClub</a>
        </div>
        <div>
        <a href="#" class="ui-button px-4 my-3" >BROWSE </br> myCaveClub</a>
        </div>
      </div>
      <div id="right" class="col-6" style="text-align: center">
        <h6 class="cave-rewards">my cave rewards </h6>
        <div class="row">
          <div class="col-6 text-center"> myCave Rewards Points:</div>
          <div class="col-6 text-left" > 150</div>
        </div>
        <div class="row">

          <div class="offset-6 col-6 text-left" >Placeholder</div>
        </div>
        <div class="cave-rewards"></div>
        <div>
          <a href="#" class="ui-button px-4 my-3" >REDEEM </br> myCaveClub</a>
        </div>
        <div>
          <a href="#" class="ui-button px-4 my-3">UPDATE </br> myCaveClub</a>
        </div>
        <div>
          <a href="#" class="ui-button px-4 my-3" >LOGOUT </br> myCaveClub</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
export default {
  name: "AccountViewComponent",
  apollo: {

  }
}
</script>
