<template>
  <div id="randy">
    <div id="site-logo">
      <a href="/" title="Home">
        <img src="/assets/logos/cavespring-vineyard-logo.png" style="width:100%;"
             alt="Home" id="logo" />
      </a>
    </div>
    <div id="print-logo">
      <img src="/assets/logos/cavespring-vineyard-logo.png" width="200" border="0" />
    </div>

    <h1 class="shop-title">WINE SHOP ACCOUNT TERMS</h1>
    <div style="clear: both;"></div>
    <div id="content" class="policy-content">
      <div id="wines">
        <p>
          By creating an account with the Cave Spring Wine Shop, you agree to provide Cave Spring with personal
          information including your address, phone number, and other relevant contact information in order to
          complete your purchase.
          <br/><br/>
          Only persons 19 years of age or older may purchase wine in Ontario and delivery must be taken by someone who
          is 19 years of age or older.
          <br/><br/>
          The Cave Spring Wine Shop will not store any of your payment information after each transaction. You will be
          asked to enter your payment method & credit card information every time you make a purchase.</p>
      </div>
      <div id="extra">

        <h1 class="shop-title">SHIPPING POLICY</h1>

        <p style="text-decoration: underline; font-weight: 900; ">Within Ontario</p>
        <p>
          Most Cave Spring Wine Shop orders will be shipped via courier (The Messengers International) within 1-2
          business days. There are some areas of Northern Ontario that will receive their orders via Canada Post. You
          will receive an email with your shipping confirmation and tracking number when your order is shipped.
          <br/><br/>
          Current Ontario Alcohol legislation dictates that someone 19 years of age or older must sign for any
          purchase including wine. You may be asked to provide Photo ID to confirm your age when your wine is
          delivered.
          <br/><br/>
          When shipping to a residential address, The Messengers International will contact you via phone to arrange a
          delivery time window for the following business day. Canada Post parcels will be delivered in accordance to
          Canada Post regulations and may require you to pick up your order at the local Post Office.
          <br/><br/>
          The Messengers International shipments to a business address will be automatically delivered during regular
          business hours.<br/><br/>
          Approximate Delivery Charges Within Ontario:<br/>
        </p>
        <table class="table table-bordered table-sm" style="width: 86%; margin: 0 auto;">
          <tr>
            <th style="width: 40%;">&nbsp;</th>
            <th>1-12 bottles</th>
            <th>13-24 bottles</th>
            <th>25-36 bottles</th>
          </tr>
          <tr>
            <td>Golden Horseshoe &amp; GTA</td>
            <td>$12.00</td>
            <td>$15.00</td>
            <td>$18.00</td>
          </tr>
          <tr>
            <td>SW Ontario, Ottawa, Kingston</td>
            <td>$12.00</td>
            <td>$15.00</td>
            <td>$18.00</td>
          </tr>
          <tr>
            <td>Central Ontario</td>
            <td>$20.00</td>
            <td>$25.00</td>
            <td>$30.00</td>
          </tr>
          <tr>
            <td>Nothern Ontario</td>
            <td>$20.00</td>
            <td>$25.00</td>
            <td>$30.00</td>
          </tr>
        </table>
        <br/>
        <p style="text-decoration: underline; font-weight: 900;">Outside of Ontario</p>
        <p>
          Under current regulations, we are only permitted to ship wine to certain provinces outside of Ontario for
          personal consumption only. These provinces are British Columbia, Alberta, and Manitoba. As more provinces
          amend their regulations, we will add these to <a href="https://wineshop.cavespring.ca">wineshop.cavespring.ca</a>.
          <br/><br/>
          All orders outside of Ontario will be shipped via Canada Post Expedited Parcel. The estimated delivery time
          will be 2-7 days, depending on the final destination. You will receive a confirmation email with your
          tracking number when your package is shipped. You will be required to show proof of age at time of delivery.
          <br/><br/>
          Delivery Charges Outside of Ontario:<br/>
        </p>
        <table class="table table-bordered table-sm  border border-black " style="width: 86%; margin: 0 auto;">
          <tr>
            <th style="width: 30%;">&nbsp;</th>
            <th>1-6 bottles</th>
            <th>7-12 bottles</th>
            <th>13-18 bottles</th>
            <th>18-24 bottles</th>
          </tr>
          <tr>
            <td>Alberta</td>
            <td>$25.00</td>
            <td>$35.00</td>
            <td>$60.00</td>
            <td>$70.00</td>
          </tr>
          <tr>
            <td>British Columbia</td>
            <td>$25.00</td>
            <td>$35.00</td>
            <td>$60.00</td>
            <td>$70.00</td>
          </tr>
          <tr>
            <td>Manitoba</td>
            <td>$20.00</td>
            <td>$27.00</td>
            <td>$47.00</td>
            <td>$54.00</td>
          </tr>
        </table>
        <br/>
        <p style="text-decoration: underline; font-weight: 900;">Winter Shipping Policy</p>
        <p>Cold winter weather may adversely affect the condition of your wine during shipment. For orders out of
          province as well as outside of Southern Ontario, Cave Spring ships via Canada Post. During extreme winter
          conditions there is the risk of wine shipments freezing in transit. Canada Post has informed us that they
          are not able to guarantee shipments from freezing, regardless of level of postal delivery (i.e..
          Xpresspost). In lieu of this, we regret that we will not be responsible for, and will not replace wine, that
          is damaged due to extreme weather conditions. We will continue to strive to find alternatives for shipping
          during this period as some options may be available within large cities outside of Ontario.
          <br/><br/>*Please note that we will guarantee the condition of couriered wine shipments within Southern
          Ontario and Ottawa. Please call us at the winery if you have any questions regarding this matter.
          1-888-806-9910 ext. 302
          <br/><br/>We want to ensure our wine arrives in premium condition. Thank you for your cooperation.<br/></p>

        <h1 class="shop-title">PRIVACY POLICY</h1>
        <div style="clear: both;"></div>
        <p>
          Your privacy is important to us. Any personal information entered and / or stored in your account will only
          be used to facilitate the purchase of Cave Spring products while you are 'Logged In' to the online Wine
          Shop.
          <br/><br/>
          You can be assured that your name and contact information will be kept strictly confidential and will not be
          shared with any third party without your permission.
        </p>

        <h1 class="shop-title">DAMAGED PRODUCT & RETURN POLICY</h1>
        <div style="clear: both;"></div>
        <p>
          If any part of your order arrives to you damaged, please contact us and the Cave Spring Wine Shop will make
          arrangements to remedy the situation.
          <br/>
          Any returns must be pre-approved. Please contact the Cave Spring Wine Shop to arrange a return:
          <br/>
          Cave Spring Wine Shop<br/>
          <a href="mailto:wineshop@cavespring.ca" target="_blank">wineshop@cavespring.ca</a><br/>
          1-888-806-9910 ext 304<br/>
        </p>
      </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "StorePoliciesComponent",
}
</script>
