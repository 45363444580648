<template>
  <div class="relative z-20 !mx-0">

    <div class="w-full md:hidden">
      <div class="buttons flex justify-evenly flex-wrap lg:flex-nowrap w-full xl:w-3/4 lg:justify-between my-10">
        <div class="white-banner    my-2.5 w-2/5 lg:w-full  lg:w-full " style="position: relative;">
          <a @click="switchIt('Sensory')">
            <img class="white-banner" src='/assets/svg/sensory-banner.svg'>
            <div class="text-white w-full lg:text-19 button-style">Sensory Profile
            </div>
          </a>
        </div>

        <div class="white-banner my-2.5   w-2/5 lg:w-full" style="position: relative;">
          <a v-if="webReviews.entries" @click="switchIt('Review')">
            <img class="white-banner" src='/assets/svg/review-banner.svg'>
            <div class="text-white w-full lg:text-19 button-style">Reviews
            </div>
          </a>
        </div>
        <div class="white-banner my-2.5   w-2/5 lg:w-full " style="position: relative;">
          <a @click="switchIt('Tech')">
            <img class="white-banner" src='/assets/svg/tech-banner.svg'>
            <div class="text-white w-full lg:text-19 button-style">Technical Data
            </div>
          </a>
        </div>
        <div class=" white-banner my-2.5  md:my-0 w-2/5 lg:w-full" style="position: relative;">
          <a v-if="wineTech.entries" target="_blank"
             rel="noreferrer"  :href="this.url + wineTech.entries[0].techSheet">
            <img class=" white-banner" src='/assets/svg/product-banner.svg'>
            <div class="text-white w-full lg:text-19 button-style">Product Sheet
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="w-full hidden md:block lg:hidden">
      <div class="buttons flex  justify-between w-full my-10">
        <div class="white-banner scale-85" style="position: relative;">
          <a @click="switchIt('Sensory')">
            <img class="white-banner" src='/assets/svg/sensory-banner.svg'>
            <div class="text-white w-full lg:text-19 button-style">Sensory Profile
            </div>
          </a>
        </div>
        <div class="white-banner scale-85" style="position: relative;">
          <a v-if="webReviews.entries" @click="switchIt('Review')">
            <img class="white-banner" src='/assets/svg/review-banner.svg'>
            <div class="text-white w-full lg:text-19 button-style">Reviews
            </div>
          </a>
        </div>
        <div class="white-banner scale-85" style="position: relative;">
          <a @click="switchIt('Tech')">
            <img class="white-banner" src='/assets/svg/tech-banner.svg'>
            <div class="text-white w-full lg:text-19 button-style">Technical Data
            </div>
          </a>
        </div>
        <div class=" white-banner scale-85" style="position: relative;">
          <a v-if="wineTech.entries" target="_blank"
             rel="noreferrer"  :href="this.url + wineTech.entries[0].techSheet">
            <img class=" white-banner" src='/assets/svg/product-banner.svg'>
            <div class="text-white w-full lg:text-19 button-style">Product Sheet
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="hidden lg:block w-3/4">
      <div class="buttons flex  justify-between w-full my-10">
        <div class="white-banner scale-85" style="position: relative;">
          <a @click="switchIt('Sensory')">
            <img class="white-banner" src='/assets/svg/sensory-banner.svg'>
            <div class="text-white w-full lg:text-19 button-style">Sensory Profile
            </div>
          </a>
        </div>
        <div class="white-banner scale-85" style="position: relative;">
          <a v-if="webReviews.entries" @click="switchIt('Review')">
            <img class="white-banner" src='/assets/svg/review-banner.svg'>
            <div class="text-white w-full lg:text-19 button-style">Reviews
            </div>
          </a>
        </div>
        <div class="white-banner scale-85" style="position: relative;">
          <a @click="switchIt('Tech')">
            <img class="white-banner" src='/assets/svg/tech-banner.svg'>
            <div class="text-white w-full lg:text-19 button-style">Technical Data
            </div>
          </a>
        </div>
        <div class=" white-banner scale-85" style="position: relative;">
          <a v-if="wineTech.entries"  target="_blank"
             rel="noreferrer" :href="this.url + wineTech.entries[0].techSheet">
            <img class=" white-banner" src='/assets/svg/product-banner.svg'>
            <div class="text-white w-full lg:text-19 button-style">Product Sheet
            </div>
          </a>
        </div>
      </div>
    </div>

    <div v-if="review && webReviews.entries.length" class="variable-div text-font-h3">
      <h3 class="text-font-blue text-xl mb-5">REVIEWS</h3>
    <div v-for="entries in webReviews.entries">



      <img v-if="entries.publicationImage" class="max-w-[150px]" :src="entries.publicationImage">

      <p class="mb-7 font-Palatino w-3/4 "><span v-if="entries.reviewPublication"><b class="mr-1">Publication:</b>{{ entries.reviewPublication }}</span>  <span v-if="entries.reviewAuthor"><br> <b
          class="mr-1">Author:</b>{{ entries.reviewAuthor }}</span>  <span v-if="entries.ratingPoint"> <br> <b class="mr-1">Rating Point:</b>{{ entries.ratingPoint }}</span>  <span v-if="entries.ratingOther" class="text-font-blue"><br><b>{{entries.ratingOther}}</b></span>  <br> <b class="mr-1">Review:</b>{{entries.webstoreReview}}
        <span v-if="entries.reviewDate"><br> <b
            class="mr-1">Date:</b>{{entries.reviewDate}}</span>
      </p>
      <div class="w-3/4 border-b-2 border-grey-light mb-5"></div>

    </div>
    </div>
    <div v-else-if="review && !webReviews.entries.length" class="variable-div text-font-h3">

      <h3 class="text-font-blue text-xl mb-5">No Reviews At This Time</h3>

      </div>

    <div v-if="tech && wineTech.entries" class="variable-div text-font-h3">

      <h3 class="text-font-blue text-xl mb-7">Technical Data</h3>
      <h3 class="text-font-blue text-xl mb-5">Vinification</h3>
      <p class="mb-7 font-Palatino ">{{ wineTech.entries[0].wineVinification }}</p>
      <h3 class="text-font-blue text-xl mb-5">Soil</h3>
      <p class="mb-7 font-Palatino">{{ wineTech.entries[0].wineSoil }}</p>
      <h3 class="text-font-blue text-xl mb-5">Topography</h3>
      <p class="mb-7 font-Palatino">Elevation: {{ wineTech.entries[0].elevation }} <br>
        Slope/Exposure: {{ wineTech.entries[0].slope }}</p>
      <h3 class="text-font-blue text-xl mb-5">Bottling Data</h3>
      <p class="mb-7 font-Palatino ">Bottling Date: {{ wineTech.entries[0].bottlingInformation[0].bottlingdate }}
<!--        <br>-->
<!--        Titratable Acidity: {{ wineTech.entries[0].bottlingInformation[0].acid }}-->
        <br>
        Production: {{ wineTech.entries[0].bottlingInformation[0].production }}
      </p>

    </div>
    <div v-if="sensory && wineTech.entries" class="variable-div text-font-h3">

      <h3 class="text-font-blue text-xl mb-5"></h3>
      <h3 class="text-font-blue text-xl mb-5">Sensory Profile</h3>
      <p class="mb-7 font-Palatino "><b class="mr-1">Color:</b>{{ wineTech.entries[0].wineTastingColour }} <br> <b
          class="mr-1">Nose:</b>{{
        wineTech.entries[0].wineTastingNose
        }} <br> <b class="mr-1">Palate:</b>{{ wineTech.entries[0].wineTastingPalate }}</p>
      <h3 class="text-font-blue text-xl mb-5">Food Pairings</h3>
      <p class="mb-7 font-Palatino">{{ wineTech.entries[0].wineServingSuggestions }}</p>
      <h3 class="text-font-blue text-xl mb-5">Ageability</h3>
      <p class="mb-7 font-Palatino">{{ wineTech.entries[0].wineAgeability }}</p>
    </div>
    <div v-if="wineTech.entries && facts" class="variable-div text-font-h3">

      <h3 class="text-font-blue text-xl mb-5">Vintage</h3>
      <p class="mb-7 font-Palatino ">{{ wineTech.entries[0].wineVintage }}</p>
      <h3 class="text-font-blue text-xl mb-5">Composition</h3>
      <p class="mb-7 font-Palatino">{{ wineTech.entries[0].wineComposition }}</p>
      <h3 class="text-font-blue text-xl mb-5">Origin</h3>
      <p class="mb-7 font-Palatino">Appellation: {{ wineTech.entries[0].appellation }} <br>
        Site: {{ wineTech.entries[0].wineOrigin }}</p>
      <div class="flex items-center mb-7">
        <h3 class="text-font-blue text-xl my-0 mr-2.5">Alcohol</h3>
        <p class="mr-8 font-Palatino"> {{ wineTech.entries[0].bottlingInformation[0].alchoholpercentage }}</p>
        <h3 class="text-font-blue text-xl my-0 mr-4">Residual Sugar</h3>
        <p class="font-Palatino">{{
          wineTech.entries[0].bottlingInformation[0].residualSugar
          }}</p>
      </div>
      <div class="flex items-center mb-7">
        <h3 class="text-font-blue text-xl my-0 mr-2.5">Availability</h3>
        <p class="font-Palatino"> <span class="font-Palatino" >Ontario</span>  <span class="text-19">•</span> {{ wineTech.entries[0].wineAvailability }}</p>
      </div>
    </div>
    <!-- {{test}}
    {{ wineTech.entries[0].bottlingInformation[0].alchoholpercentage }}
    <ul>
      <li v-for="(value, key) in wineTech.entries">{{ key }}: {{ value }} </li>
    </ul> -->

  </div>
</template>

<script>
import gql from 'graphql-tag'
export default {
  name: "TechDataComponent",
  props: ['test', 'url'],
  apollo: {
    // products: {
    //   query: gql`query GetProducts {
    //     products(type: "wine") {
    //       title
    //       id
    //         ... on wine_Product {
    //           id
    //           wineCategory
    //           wineCode
    //           wineVolume
    //           winePrice
    //           wineItemNumber
    //           wineQuantity
    //           itemNumberMatch
    //         }
    //       variants {
    //         stock
    //         price
    //         id
    //       }
    //     }
    //   }`
    // },
    wineTech: {
      query: gql`query GetEntries($sku: [QueryArgument]) {
        entries(type:"wineTech" sku:$sku) {
        title
          ... on wineTech_wineTech_Entry{
        wineVintage
        wineComposition
        appellation
        wineOrigin
        wineAvailability
        wineServingSuggestions
        wineAgeability
        wineTastingNose
        wineTastingColour
        wineTastingPalate
        wineSoil
        wineVinification
        elevation
        slope
        techSheet

        bottlingInformation { 
          ... on bottlingInformation_bottlingInformation_BlockType {
            alchoholpercentage
            residualSugar
            bottlingdate
            acid
            ph
            production
          }
        }
        
  }
  }

      }`,
      update: data => data,
      variables() {
        return {
          sku: this.test
        }
      }
    },
    webReviews: {
      query: gql`query GetReviews($reviewSku: [QueryArgument]) {
        entries(section:"webstoreReviews" reviewSku:$reviewSku) {
           title
    ... on webstoreReviews_default_Entry {

      ratingOther
      ratingPoint
      ratingStar
      reviewAuthor
      reviewDate
      reviewHeadline
      reviewMonth
      reviewPublication
      reviewPublished
      reviewRaw
      reviewSku
      reviewSweetness
      reviewVintage
      reviewWebsite
      reviewWine
      reviewWineType
      reviewYear
      webstoreReview
      publicationImage
}
  }
  }`,
      update: data => data,
      variables() {
        return {
          reviewSku: this.test
        }
      }
    },
//     allReviews: {
//       query: gql`query GetAllReviews($reviewSku: [QueryArgument]) {
//         entries(reviewSku:$reviewSku) {
//     ... on webstoreReviews_default_Entry {
//
//       ratingOther
//       ratingPoint
//       ratingStar
//       reviewAuthor
//       reviewDate
//       reviewHeadline
//       reviewMonth
//       reviewPublication
//       reviewPublished
//       reviewRaw
//       reviewSku
//       reviewSweetness
//       reviewVintage
//       reviewWebsite
//       reviewWine
//       reviewWineType
//       reviewYear
//       webstoreReview
//       publicationImage
// }
//   }
//   }`,
//       update: data => data,
//       variables() {
//         return {
//           reviewSku: this.test
//         }
//       }
//     },
 },


  data() {
    return {

      wineTech: {},
      sensory : false,
      review : false,
      tech: false,
      facts: true,
      skuuu: this.test,
    

    }

  },
  computed: {

  },

  methods: {
    switchIt: function (value) {
      switch (value) {
        case "Sensory":
          console.log("case 1");
          this.sensory = !this.sensory;
          this.review = false;
          this.tech = false;
          if (this.facts === false && this.sensory === false) {
            this.facts = true;
          } else {
            this.facts = false;
          }
          break;
        case "Review":
          console.log("case 2")
          this.review = !this.review;
          this.sensory = false;
          this.tech = false;
          if (this.facts === false && this.review === false) {
            this.facts = true;
          } else {
            this.facts = false;
          }
          break;
        case "Tech":
          console.log("case 3")
          this.tech = !this.tech;
          this.sensory = false;
          this.review = false;
          if (this.facts === false && this.tech === false) {
            this.facts = true;
          } else {
            this.facts = false;
          }
          break;
        case "Product":
          console.log("Download Tech Sheet")
          break;
      }
    },
  }



}
</script>