export default {
    async removeCartItem(lineItem) {
        let request = {};
        try {
            request = await axios.post('?action=commerce/cart/update-cart',
                {
                    lineItems: {[lineItem.id]: {'remove': 1}}
                }
            );
        } catch (error) {
            console.log(error);
            throw new Error("Unable to reach cart. Please try again later");
        }

        if (!request.data.success) {
            let error = "";
            Object.entries(request.data.errors).forEach(element => {
                error += element[1];
            })
            throw new Error(error);
        }
    },
    async getCart() {
        let response = {}
        try {
            response = await axios.get('?action=commerce/cart/get-cart');
        } catch (error) {
            throw new Error("Unable to obtain cart from server.")
        }
        return response.data.cart;
    },
    async setMembershipType(type) {
        let request = {};
        try {
            request = await axios.post('?action=commerce/cart/update-cart',
                {
                    clubType: type
                }
            );
        } catch (error) {
            console.log(error);
            throw new Error("Error");
        }
    },
    async setCartType(gatewayId) {
        let request = {};
        try {
            request = await axios.post('?action=commerce/cart/update-cart',
                {
                    gatewayId: gatewayId,
                }
            );
            console.log(gatewayId)
        } catch (error) {
            console.log(error);
            throw new Error("Unable to reach cart. Please try again later");
        }
    },
    async addToCart(product, quantity) {
        let request = {};
        try {
            request = await axios.post('?action=commerce/cart/update-cart',
                {
                    purchasableId: product.id,
                    qty: quantity
                }
            );
        } catch (error) {
            console.log(error);
            throw new Error("Unable to reach cart. Please try again later");
        }

        if (!request.data.success) {
            let error = "";
            Object.entries(request.data.errors).forEach(element => {
                error += element[1];
            })
            throw new Error(error);
        }
    },
    async updateLineItemQuantity(lineItemId, quantity) {
        let request = {};
        let data = {
            lineItems: {
                [lineItemId]: {
                    qty: quantity
                }
            }
        }
        try {
            request = await axios.post('?action=commerce/cart/update-cart', data);
        } catch (error) {
            console.log(error);
            throw new Error("Unable to reach cart. Please try again later");
        }

        if (!request.data.success) {
            let error = "";
            Object.entries(request.data.errors).forEach(element => {
                error += element[1];
            })
            throw new Error(error);
        }
    }
}