import Vue from 'vue'
import cart from './modules/cart'
import Vuex from 'vuex'

Vue.use(Vuex)
// Vue.config.devtools = true

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        cart
    },
    strict: debug
})