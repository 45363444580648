<template>
  <div>
    <div id="site-logo">
      <a href="http://cavespring.ca/mycave-rewards">
        <img style="cursor: pointer;" src="http://test.wineshop.cavespring.ca/css/images/newlogo201804.png" height="80/">
      </a>
    </div>
    <h1 class="shop-title mx-4">SHOPPING CART</h1>
    <div style="clear: both;"></div>
    <div id="content" v-if="getCartProducts.length">
      <div id="cart_content">
        <form style="margin-top: 20px;">
          <div id="cart_items_content">
            <table class="w-100 cart-table">
            <tbody><tr>
              <th>&nbsp;</th>
              <th>Description</th>
              <th>Price</th>
              <th style="text-align: center;">Quantity</th>
              <th style="text-align: right;">Sub-Total</th>
            </tr>
            <template v-for="item in getCartProducts">

              <cart-item-component :key="item.id" :purchaseableId="item.purchasableId"></cart-item-component>
            </template>
            <tr>
              <th colspan="3"></th>
              <th style="text-align: right;">Subtotal*:</th>
              <th style="text-align: right;">{{ getCartSubtotalTotalPrice }}</th>
            </tr>
            <tr>
              <th colspan="3"></th>
              <th style="text-align: right;">Shipping:</th>

              <th v-if="getCartShippingPrice === 0" style="text-align: right; font-style: italic; font-size:85%;">Shipping Calculated Upon Checkout</th>
              <th v-else style="text-align: right;" > {{getCartShippingPrice}}</th>
            </tr>
            <tr>
              <th colspan="3"></th>
              <th style="text-align: right;">HST (shipping &amp; gift shop):</th>
              <th style="text-align: right;">{{ getCartTax }}</th>
            </tr>
            <tr>
              <th colspan="3"></th>
              <td style="text-align: right; font-style: italic; color: #666666; font-size: 85%;">*Includes {{ bottleDeposit }} in bottle deposits</td>
              <th style="text-align: right;">&nbsp;</th>
            </tr>
            <tr class="bottom">
              <th colspan="3"></th>
              <th style="text-align: right;">Total:</th>
              <th style="text-align: right;">{{ getCartTotalPrice }}</th>
            </tr>
            </tbody></table>
            <div class="m-2 mb-4">
            <a href="/wineshop/checkout" id="update" style="font-size: 85%; margin-right: 5px; float: right; color: white;"
               class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only p-2" role="button" aria-disabled="false">
              <span class="ui-button-text">Checkout</span></a></div>
            <div style="clear: both;"></div>
          </div>
        </form>
      </div>
    <div id="dialog"></div>
    <div id="shipping_warning_dialog" style="display: none">
      <h1>Winter Shipping Policy</h1>
      <br>
      <p style="line-height: 150%;width: 90%;margin: 0px auto;">Please be advised: Cold winter weather may adversely
        affect the condition of your wine during shipment. For orders out of province as well as outside of Southern
        Ontario, Cave Spring ships via Canada Post. During extreme winter conditions there is the risk of wine shipments
        freezing in transit. Canada Post has informed us that they are not able to guarantee shipments from freezing,
        regardless of level of postal delivery (i.e.. Xpresspost). In lieu of this, we regret that we will not be
        responsible for, and will not replace wine, that is damaged due to extreme weather conditions. We will continue
        to strive to find alternatives for shipping during this period as some options may be available within large
        cities outside of Ontario.
        <br><br>*Please note that we will guarantee the condition of couriered wine shipments within Southern Ontario
        and Ottawa. Please call us at the winery if you have any questions regarding this matter. 1-888-806-9910 ext.
        302
        <br><br>We want to ensure our wine arrives in premium condition. Thank you for your cooperation.<br></p>
    </div>
    <div id="delivery_in_ontario_dialog" style="display: none">
      <h1>Delivery within Ontario</h1>
      <br>
      <p style="line-height: 150%;width: 90%;margin: 0 auto;">
        Below is a list of days and areas within Ontario that we are currently shipping to via courier or company
        driver. Our goal is to make this process as seamless as possible and to ensure we deliver the same level of
        service you have come to expect from Cave Spring.
      </p>
      <br>
      <ul style="line-height: 150%;width: 90%;margin: 0 auto;">
        <li style="margin-bottom: 10px;">
          <strong>Monday:</strong>
          <ul>
            <li>Golden Horseshoe and City of Toronto west of the DVP</li>
          </ul>
        </li>
        <li style="margin-bottom: 10px;">
          <strong>Tuesday:</strong>
          <ul>
            <li>City of Toronto - East of the DVP, south of the 401- East York/Scarborough</li>
            <li>Golden Horseshoe and City of Toronto west of the DVP</li>
          </ul>
        </li>
        <li style="margin-bottom: 10px;">
          <strong>Wednesday:</strong>
          <ul>
            <li>Golden Horseshoe and City of Toronto west of the DVP</li>
          </ul>
        </li>
        <li style="margin-bottom: 10px;">
          <strong>Thursday:</strong>
          <ul>
            <li>South Western Ontario  - as far west as London</li>
          </ul>
        </li>
        <li style="margin-bottom: 10px;">
          <strong>Friday:</strong>
          <ul>
            <li>City of Toronto - East of the DVP, south of the 401- East York/Scarborough</li>
            <li>Golden Horseshoe and City of Toronto west of the DVP</li>
            <li>Scarborough (north of 401) Richmond Hill, Markham, Aurora and Newmarket</li>
            <li>Pickering to East of Whitby - Ottawa/Peterborough</li>
          </ul>
        </li>
      </ul>
      <br>
      <p style="line-height: 150%;width: 90%;margin: 0 auto;margin-bottom: 15px;">
        <strong>*NOTE –</strong> for areas not listed please call us directly at the winery to inquire about delivery options.
        <br>
        905-562-3581 x 302
      </p>

      <p style="line-height: 150%;width: 90%;margin: 0 auto 15px;">
        <strong>We will contact you via email as soon as your order is ready to ship.</strong>
      </p>
    </div>
    <div id="learn_more_dialog" style="display: none">
      Customize a recurring order of your favourite Cave Spring wines on your terms. You chose what, how many, and when.
      <br><br>
      It can be as simple as 2 bottles of Riesling Dolomite shipped once a week. And accumulate myCave Rewards along the
      way.
      <br><br>
      <h3>Frequently Asked Questions:</h3>
      <dl>
        <dt>How do I sign-up for a myCave Subscription?</dt>
        <dd>Just log in to your account and select Create new myCave Subscription. Don't have an account yet? Select New
          Account and you can be up in running in minutes.
        </dd>
        <dt>What are the shipping charges?</dt>
        <dd>If your myCave Subscription is $80 or more, your order ships for free within Ontario!</dd>
        <dt>Is there a minimum number of bottles that I have to order?</dt>
        <dd>No, you can order as little as 1 bottle.</dd>
        <dt>Can I have more than one myCave Subscription?</dt>
        <dd>Yes you can. When you create a new order, you will be asked to select a nickname for each order.</dd>
        <dt>Why would I have more than one myCave Subscription?</dt>
        <dd>Not sure what to get someone for a gift? Give the gift of wine! You can ship your favourite Cave Spring
          wines to friends or family to share what you have discovered. You collect myCave Rewards for yourself as
          well!
        </dd>
        <dt>Can I change my subscription?</dt>
        <dd>Yes. Until your next scheduled shipment is processed, you can revise your order online by logging into your
          myCave account and selecting Update myCave Subscription.
        </dd>
        <dt>How do I cancel my order?</dt>
        <dd>Once you log into your myCave account, select Update myCave Subscription and then select Cancel my
          subscription.
        </dd>
        <dt>What happens if one of my wines is not available?</dt>
        <dd>If we go to process your subscription and one of the wines is not available, you will receive an email
          letting you know which wine is out of stock. We will not process your order until you log in and revise your
          subscription.
        </dd>
        <dt>What happens when the vintage changes of one of my wines?</dt>
        <dd>You will always receive the most current vintage available for your subscription.</dd>
      </dl>
      If you did not see the answer to your question, please let us know. You can call us at 1-888-806-9910, ext. 302 or
      email wineshop@cavespring.ca.
    </div>
  </div>
    <div v-else class="mx-4 pt-3">
      <p>Your cart is currently empty.</p>
    </div>
</div>
</template>
<script>
import {mapGetters} from "vuex";
export default {
  name: "CartPageComponent",
  data() {
    return {
      quantity: null,
    }
  },
  created() {
    this.$store.dispatch('cart/getCartFromServer');
  },
  methods: {
    updateQuantity: async function () {
      try {
        await this.$store.dispatch('cart/updateLineItemQuantity', {
          'lineItemId': this.item.id,
          'quantity': this.quantity
        })
      } catch (error) {
        console.log(error)
        this.quantity = this.item.qty;
      }
    }
  },
  computed: {
    ...mapGetters("cart", ['getCartProducts', "getCartSubtotalTotalPrice", 'getCartShippingPrice' , 'getCartTax', 'getCartTotalPrice']),

    bottleDeposit: function() {
      let bottleDep = "";
      if (this.getCartProducts.length > 0) {
        let products = this.getCartProducts;
        let amount = 0;
        for (let i = 0; i < products.length; i++) {
          amount += products[i].adjustments[1].amount;
        }
        bottleDep = "$" + amount.toFixed(2);
      }
      return bottleDep;
    }
  }
}
</script>

<style scoped>

</style>