<template>
  <div>
    <div id="site-logo">
      <a href="/" title="Home">
        <img src="/assets/logos/myCaveRewards.png" style="width:100%;"
             alt="Home" id="logo" />
      </a>
    </div>
    <div id="print-logo">
      <img src="/assets/logos/myCaveRewards.png" width="200" border="0" />
    </div>
    <div class="px-4"><h1 class="shop-title mx-3">{{ pageCategory.title }}</h1></div>
    <div id="content">
      <div v-if="!$apollo.loading" id="wines" class="row mx-lg-4">
        <div v-if="filtered.length > 0" v-for="product in filtered" class="col-md-4 col-sm-6">
          <product-card-component :product="product"
                                  @toggleModal="onCardToggle">
          </product-card-component>
        </div>
        <div v-if="filtered.length === 0">
          <p>There are currently no wines for sale in this category.</p>
        </div>
    </div>
<!--      <div v-if="$apollo.loading" class="m-auto pt-4">-->
<!--        <i class="fas fa-4x fa-spinner fa-spin"></i>-->
<!--      </div>-->
    </div>
    <div v-if="showModal">
      <transition name="modal">
        <product-modal-component @close-modal="showModal = false" :loading="$apollo.loading" :product="product" :wineProps="getProperties(product)"></product-modal-component>
      </transition>
    </div>
  </div>
</template>
<script>
import gql from 'graphql-tag'

export default {
  name: "ShopCategoryComponent",
  // props: ['url', 'code', 'title'],
  props: ['type'],
  apollo: {
    products: {
      query: gql`query GetProducts {
        products(type: "wine") {
          title
          id
            ... on wine_Product {
              id
              wineCategory
              wineCode
              wineVolume
              winePrice
              wineItemNumber
              wineQuantity
              itemNumberMatch
            }
          variants {
            stock
            price
            id
          }
        }
      }`
    },
    product: {
      query: gql`query GetProduct($itemNumber: [QueryArgument]){
        product(wineItemNumber: $itemNumber) {
          title
          id
            ... on wine_Product {
              id
              wineAppellation
              wineCode
              wineVolume
              winePrice
              wineItemNumber
              wineQuantity
              itemNumberMatch
            }
          variants {
            stock
            price
            id
          }
        }
      }`,
    variables() {
        return {
          itemNumber: this.itemNumber
        }
      }
    },
    wineProperties: {
      query: gql`query GetEntries {
        entries(type:"wineProperties") {
        ... on wineProperties_wineProperties_Entry {
            description
            descItemNumber
            wineImage {
                url
            }
          }
        }
      }`,
      update: data => data.entries
    },
    bottleDeposits: {
      query: gql` query GetBottleDeposits {
        entries {
          ... on bottleDeposits_bottleDeposits_Entry {
          bottleSize
          bottleDepositAmount
          }
        }
      }`,
      update: data => data.entries
    },
    assets: {
      query: gql`query GetAssets {
        assets {
          ... on wines_Asset {
            filename
            url
          }
        }
      }`
    }
},
  data() {
    return {
      //all products from GraphQl
      products: [],
      //all images from GraphQl
      assets:[],
      //filtered products for certain wine type
      filteredProducts: [],
      //Eventually make this a craft entry, then pull from GraphQL
      codeLegend: [
        {url: "riesling", code: "R", title: "Reisling"},
        {url: "chardonnay", code: "C", title: "Chardonnay"},
        {url: "pinot-gris", code: "X", title: "Pinot Gris"},
        {url: "gewurztraminer", code: "Z", title: "Gewurztraminer"},
        {url: "pinot-noir", code: "P", title: "Pinot Noir"},
        {url: "cabernet-franc", code: "F", title: "Cabernet Franc"},
        {url: "gamay", code: "G", title: "Gamay"},
        {url: "blends", code: "B", title: "blends"},
        {url: "rosé", code: "E", title: "Rosé"},
        {url: "cs2go", code: "M", title: "CS2Go"},
        {url: "sparkling", code: "K", title: "Sparkling"},
        {url: "sweet", code: "S", title: "Sweet"},
        {url: "back-vintages", code: "A", title: "Back Vintages"},
        {url: "special-offers", code: "L", title: "Special Offers"},
        {url: "gifts", code: "Y", title: "Gifts"},
        {url: "oliverbonacini", code: "J", title: "Oliver & Bonacini Wines"},
        {url: "shattered-glass", code: "Q", title: "Shattered Glass"},
        {url: "winery-events", code: "V", title: "Winery Events"},
        {url: "rewards", code: "H", title: "Rewards"}],
      //holds wine type url parameter, wine type code, amd a title to display at top of page
      pageCategory: {},
      //handles modal opening
      showModal: false,
      //used teo get a specific product from GraphQl
      itemNumber: null,
      //used to get wine description
      descItemNumber: null,
      //holds product from GraphQl
      product: {},
      //hold bottle deposit amounts from GraphQL to determine price
      bottleDeposits: [],
      //holds the wine description from GraphQl
      wineProperties: []

    }
  },
  created() {
    this.filterProducts();
  },
  watch: {
    $route(to, from) {
      //if URL changes change page category
      this.filterProducts();
    }
  },
  methods: {
    //function to get description and image for modal
    getProperties: function(prod) {
      let property = {};
      let wineProperties = this.wineProperties;
      //loop through all properties
      for(let i = 0 ; i < wineProperties.length; i++)
      {
        //if item numbers match, break and return
        if(prod.wineItemNumber.substring(0, prod.wineItemNumber.length-2) === wineProperties[i].descItemNumber) {
            property = wineProperties[i];
            break;
        }
      }
      return property;
    },
    onCardToggle: function(value) {
      //open modal and set product item number to get it through GraphQl and pass it to modal component
      this.itemNumber = value;
      this.showModal = true;
      this.descItemNumber = value.substring(0, 6);
    },
    filterProducts: function() {
      let items = this.codeLegend;
      //set page category to wine type by looking at URL parameter
      for(let i = 0; i < items.length; i++) {
        if(items[i].url === this.type) {
          this.pageCategory = items[i];
        }
      }
    },
    //get images by wine
    getImage: function(wine) {
      let images = this.assets;
      let num =  wine.wineItemNumber.substring(0, 6);
      if(images.length > 0) {
        for (let i = 0; i < images.length; i++) {
          //if image filename starts with 300
          if (images[i].filename.substring(0, 3) === "300") {
            //if image filename contains item number
            if (num === images[i].filename.substring(4, 10)) {
              //if image doesn't have any extra characters
              if (images[i].filename.substring(4).length === 10) {
                console.log(images[i]);
                return images[i];
              }
            }
          }
        }
      }
    },
  },
  computed: {
    filtered: function () {
      let allProducts = []
      let allProperties = []
      allProducts = this.products;
      allProperties = this.wineProperties;
      let filter = [];
      //loop through all products
      if(allProducts.length > 0) {
        for(let i = 0; i < allProducts.length; i++)
        {
          //if product code has wine category code, add it to array
          if(allProducts[i].wineCode.includes(this.pageCategory.code)){
            if(this.pageCategory.url !== "cs2go") {
              if(allProducts[i].wineCategory !== "RABA22") {
                filter.push(allProducts[i]);
              }
            }
            else {
              filter.push(allProducts[i]);
            }
          }
        }

        //loop through filtered products
          for(let x = 0; x < filter.length; x++)
          {
            let current = filter[x];
            current.image = "";
            current.description = "";
            //loop through all properties
            for(let i = 0; i < allProperties.length; i++)
            {
              //if theres a match set the image and description
              if(allProperties[i].descItemNumber === current.wineItemNumber.substring(0, current.wineItemNumber.length - 2))
              {
                current.image = allProperties[i].wineImage[0].url;
                current.description = (allProperties[i].description !== null ? allProperties[i].description : "");
              }
            }
          }
        return filter;
      }
      // return this.products.sort(this.compareWinery).filter((item) => {
      //   return item.variants[0].stock;
      // })
    },
  }
}
</script>