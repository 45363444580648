<template>
  <div>
    <div v-if="!items.length">
    </div>
  <div id="accord" v-else-if="!experience" v-for="(item, index) in items" class="px-8 text-font-title">
    <div :class="index === 0 ? 'border-y-2 border-grey-light w-5/6 my-0 mx-auto ' : 'border-b-2 border-grey-light w-5/6 my-0 mx-auto '">
      <div class="flex justify-between py-5  cursor-pointer" @click="toggleAccordion(index)">
        <div>{{item.title}}</div>
        <div class="">
          <img style="height: 20px; width: 20px" :src="currentIndex === index ? '/assets/svg/minus.svg' : '/assets/svg/plus.svg'">
        </div>
      </div>
       <collapse-transition>
      <div class="py-5 text-left p-according" v-show="currentIndex === index" v-html="item.field_accordionText_txficqlh"></div>
       </collapse-transition>
    </div>
  </div>
    <div v-else v-for="(item, index) in items" class="text-font-title">
    <div :class="index === 0 ? 'border-y-2 border-white my-0 mx-auto ' : 'border-b-2 border-white my-0 mx-auto '">
      <div class="flex justify-between py-5  cursor-pointer" @click="toggleAccordion(index)">
        <div>{{item.title}}</div>
        <div class="">
          <img style="height: 20px; width: 20px" :src="currentIndex === index ? '/assets/svg/minus.svg' : '/assets/svg/plus.svg'">
        </div>
      </div>
       <collapse-transition>
      <div class="py-5 text-left p-according" v-show="currentIndex === index" v-html="item.field_accordionText_txficqlh"></div>
       </collapse-transition>
    </div>
  </div>
  </div>
</template>

<script>
import { CollapseTransition } from "@ivanv/vue-collapse-transition"
export default {
  name: "CustomAccordion",
  components: {
    CollapseTransition,
  },
  props: ['items'],
  data() {
    return {
      currentIndex: null
    }
  },
  created() {
    console.log(this.items)
  },
  methods: {
    toggleAccordion(index) {
      if (this.currentIndex === index) {
        this.currentIndex = null;
      } else {
        this.currentIndex = index;
      }
    }
  },
  computed: {
    experience() {
      return this.$route.path === '/experience'
    }
  }
}
</script>
<style>

</style>